import React from 'react'
import Layout from '../components/Layout'
import { Banner } from '../components/Banner'
import { Posts } from '../components/Posts/Posts'
import { graphql } from 'gatsby'
const CategoryTemplate = props => {
  const {
    pageContext: { category },
  } = props
  const {
    data: {
      categories: { nodes: posts },
    },
  } = props
  return (
    <Layout>
      <Banner />
      <Posts posts={posts} title={`category / ${category}`} />
    </Layout>
  )
}
export const query = graphql`
  query MyQuery($category: String!) {
    categories: allMdx(
      filter: { frontmatter: { category: { eq: $category } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          category
          # readTime
          date(formatString: "MMMM Do, YYYY")
          slug
          # image {
          #   childImageSharp {
          #     fluid {
          #       ...GatsbyImageSharpFluid
          #     }
          #   }
          # }
        }
        excerpt
        id
      }
    }
  }
`

export default CategoryTemplate
