import React from 'react'

// import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image"
// ...GatsbyImageSharpFluid

// const query = graphql`
//   {
//     person: file(relativePath: { eq: "person.png" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `

export const Banner = () => (
  // const { person } = useStaticQuery(query)
  // <header className="hero">
  // {showPerson && (
  //   <Image fluid={person.childImageSharp.fluid} className="hero-person" />
  // )}

  // </header>
  <div
    style={{
      fontFamily: `'Open Sans', sans-serif`,
      fontWeight: `300`,
      fontSize: `18px`,
      lineHeight: `.8em`,
      textAlign: `center`,
      paddingTop: `220px`,
    }}
  >
    <h1
      style={{
        fontFamily: `Rubik, sans-serif`,
        fontWeight: `400`,
        textTransform: `lowercase`,
      }}
    >
      hi and welcome to my cyber space{' '}
      <span role="img" aria-label="rocket">
        🚀
      </span>
    </h1>
    <p style={{ fontSize: `24px`, marginBottom: `130px` }}>
      "everything is unknown until its known"
    </p>
    <hr />
  </div>
)
